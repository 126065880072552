<template>
    <div id="pageTable">
        <v-container
            class="d-flex align-center justify-center"
            style="height: 80vh; width: 80vw;"
            v-if="rol == 'admin-sucursal' || rol == 'admin-depto' || rol == 'empleado' "
        >
            <v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>

        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Configuración de notificaciones de contratos'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :showAdvancedFilters="rol == 'root' ? true : false"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" >
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">  
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td v-if="rol=='root'"><div>{{item.nombre_cliente}}</div></td>
                                <td><div>{{item.dias_anticipacion}}</div></td>
                                <td><div>{{item.aplicar_envio ? 'SI':'NO'}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template>
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" class="d-flex justify-center my-10" v-if="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-if="!isLoading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <template v-if="rol == 'root'">
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Cliente"
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors"
                                                        :readonly="accion == 'add'?false:true"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                            <VuetifyMultipleAutocomplete
                                                outlined
                                                item_text="nombreClave"
                                                :items="itemsRegistrosPatronales"
                                                :valorDefecto="configuracion.registros_patronales"
                                                @dataMultiple="(data)=> configuracion.registros_patronales = data"
                                                label="Registro patronal"
                                                ref="multRegistro"
                                                :rules="'required'"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                            <VuetifyMultipleAutocomplete
                                                outlined
                                                item_text="nombre"
                                                :items="itemsUsuarios"
                                                :valorDefecto="configuracion.usuarios"
                                                @dataMultiple="(data)=> configuracion.usuarios = data"
                                                label="Usuarios"
                                                ref="multUsuarios"
                                                :rules="'required'"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                            <ValidationProvider v-slot="{ errors }" name="Días de anticipación" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Días de anticipación" 
                                                    class="pa-0 ma-0" 
                                                    v-model="configuracion.dias_anticipacion" 
                                                    :error-messages="errors" 
                                                    required
                                                    v-mask="'###'"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-checkbox class="pa-0 checkboxVuetify" v-model="configuracion.aplicar_envio" label="Aplicar envío"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>
<script>
/* API's */
import apiContratos from '@/api/contratos';
import apiClientes from "@/api/clientes";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiUsuarios from "@/api/usuarios";

/* COMPONENTES */
import Datatable from '@/components/datatable/Datatable.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';

//PLUGINS
import Notify from '@/plugins/notify';

export default {
    components: {
        "data-table": Datatable,
        VuetifyMultipleAutocomplete,
    },
    data() {
        return {
            datosLogin  : null,
            rol         : null,
            url: "contratos/configuraciones-notificaciones/find",
            columns: [
                {
                    label: "Cliente",
                    name: "cliente_id",
                    filterable: false,
                },
                {
                    label: "Días de anticipo",
                    name: "dias_anticipacion",
                    filterable: false,
                },
                {
                    label: "Aplica envio",
                    name: "aplicar_envio",
                    filterable: false,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    filterable: false,
                    align: 'center'
                },
            ],
            filters: {
                paginate    : true,
                order_by    : 'nombre',
                cliente_id  : null,
                activo      : true,
            },

            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            imagen          : "",
            clientes        : [],
            cliente_value   : null,
            itemsRegistrosPatronales: [],
            itemsUsuarios           : [],
            configuracion           : {
                id                  : null,
                cliente_id          : null,
                registros_patronales: [],
                aplicar_envio       : null,
                dias_anticipacion   : null,
                usuarios            : []
            },
            
            
        }
    },
    watch: {
        cliente_value: function(val, oldVal){
            if(val!= null){
                this.getRegistrosPatronales(val);
                this.getUsuarios(val)
            }
        },
    },
    methods: {
        async abrirModal(accion, data = null){
            await this.resetValues();
            this.accion     = accion;
            this.isLoading  = true;

            if(this.rol == 'root'){
                this.getClientes();
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
            }

            if(accion == "add") {
                this.tituloModal    = "Nueva configuración";
                this.imagen         = "/static/modal/catalogoSATcreate.svg";
            } else {
                this.tituloModal                        = "Actualizar configuración";   
                this.imagen                             = "/static/modal/catalogoSATupdate.svg";
                this.configuracion.id                   = data.id;
                this.configuracion.cliente_id           = data.cliente_id;
                //this.configuracion.registros_patronales = data.registros_patronales; //string
                this.configuracion.registros_patronales = JSON.parse(data.registros_patronales); //object
                this.configuracion.usuarios             = JSON.parse(data.usuarios);
                this.configuracion.aplicar_envio        = data.aplicar_envio;
                this.configuracion.dias_anticipacion    = data.dias_anticipacion;
                this.cliente_value                      = data.cliente_id;
            }

            this.isLoading  = false;
            setTimeout(()=>{ this.dialog = true }, 100);
        },
        guardar(){
            this.$refs.form.validate().then(success => {
            this.isSaving = true;
            if(!success) {
                this.isSaving = false;
                return;
            }

            this.configuracion.cliente_id = this.cliente_value;

            if(this.accion === "add") {
                apiContratos.storeConfiguracion(this.configuracion).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "La configuración se guardó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.accion === "update") {
                apiContratos.updateConfiguracion(this.configuracion).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "La configuración se actualizó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            });
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar la configuración?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    apiContratos.deleteConfiguracion(data.id).then(response => {
                        this.$refs.tabla.getData();
                        Notify.Success("Configuración eliminada", "La configuración ha sido eliminada satisfactoriamente");
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        async cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            await this.resetValues();
        },
        async resetValues() {
            this.cliente_value          = null;
            this.configuracion   = {
                id                  : null,
                cliente_id          : null,
                registros_patronales: [],
                usuarios            : [],
                aplicar_envio       : null,
                dias_anticipacion   : null,
                
            };
            this.itemsRegistrosPatronales = [];
            this.itemsUsuarios = [];
            this.isLoading  = false;
            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false };
            apiClientes.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((error) => {
                console.error(error);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        async getRegistrosPatronales(cliente = null){
            this.itemsRegistrosPatronales = [];

            if(cliente == null){
                return;
            }
            
            let param = { 
                activo: true, 
                paginate: false, 
                cliente_id: cliente 
            };
            await apiRegistroPatronal.find(param).then(response => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.itemsRegistrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
        },
        async getUsuarios(cliente = null){
            this.itemsUsuarios = [];

            if(cliente == null){
                return;
            }
            
            let param = { 
                cliente_id: cliente,
                usuario_id: this.datosLogin.id
            };
            await apiUsuarios.getUsuariosAdministradores(param).then(response => {
                this.itemsUsuarios = response.data;
               
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los usuarios.");
            });
        },
        async loadModalData(){
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else {
                this.cliente_value = this.datosLogin.cliente_id;
            } 
        },
        setFilters() {
            let cliente = this.cliente_value;

            if (this.rol !== "root") {
                cliente = this.datosLogin.cliente_id;
            } 

            let filterParams = {
                cliente_id: cliente,
            };

            let defaultParams = {
                activo    : true,
                paginate  : true,
                order     : "asc"
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;
            this.cliente_value = null;
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        if(this.rol != 'root'){
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.cliente_value = this.datosLogin.cliente_id;
            this.columns= [
                {
                    label: "Días de anticipo",
                    name: "dias_anticipacion",
                    filterable: false,
                },
                {
                    label: "Aplica envio",
                    name: "aplicar_envio",
                    filterable: false,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    filterable: false,
                    align: 'center'
                },
            ]
        }
    },
    mounted() {
        
    },
}
</script>
<style scoped>
    
</style>